@font-face {
  font-family: "PoynterOSDisp Bold";
  src: local("PoynterOSDisp Bold"),
    url(./assets/fonts/PoynterOSDisp-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "Graphik Semibold";
  src: local("Graphik Semibold"),
    url(./assets/fonts/Graphik-Semibold.otf) format("opentype");
}

@font-face {
  font-family: "Graphik Light";
  src: local("Graphik Light"),
    url(./assets/fonts/Graphik-Light.otf) format("opentype");
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

textarea {
  resize: none;
}
/* start override bootstrap */
.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto {
  padding-right: 5px;
  padding-left: 5px;
}
.row {
  margin-right: -5px;
  margin-left: -5px;
  text-align: left;
}

input::placeholder,
textarea::placeholder {
  font-size: 14px;
  line-height: 21px !important;
  color: #2d2d2d !important;
  opacity: 0.5 !important;
}
input,
textarea,
div,
span {
  font-size: 14px;
  line-height: 21px;
}

.icon {
  width: 0.7em !important;
  height: 0.7em !important;
}

/* #region  */
/* The container checkbox*/
.container-checkbox {
  display: block;
  position: relative;
  padding-left: 26px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.check-mark {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  border: 1px solid #ced4da;
  border-radius: 3px;
}

/* On mouse-over, add a grey background color */
.container-checkbox:hover input ~ .check-mark {
  background-color: #ccc;
}

/* Create the check-mark/indicator (hidden when not checked) */
.check-mark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the check-mark when checked */
.container-checkbox input:checked ~ .check-mark:after {
  display: block;
}

/* Style the check-mark/indicator */
.container-checkbox .check-mark:after {
  left: 5px;
  top: 2px;
  width: 6px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(42deg);
  -ms-transform: rotate(42deg);
  transform: rotate(42deg);
}

.top--2px {
  position: relative;
  top: -2px;
}
/* #endRegion */

.cur {
  cursor: pointer;
}

.op-1 {
  opacity: 1 !important;
}
.op-0-1 {
  opacity: 0.1 !important;
}
.op-0-2 {
  opacity: 0.2 !important;
}
.op-0-3 {
  opacity: 0.3 !important;
}
.op-0-4 {
  opacity: 0.4 !important;
}
.op-0-5 {
  opacity: 0.5 !important;
}
.op-0-6 {
  opacity: 0.6 !important;
}
.op-0-7 {
  opacity: 0.7 !important;
}
.op-0-8 {
  opacity: 0.8 !important;
}
.op-0-9 {
  opacity: 0.9 !important;
}
.fw-100 {
  font-weight: 100 !important;
}
.fw-200 {
  font-weight: 200 !important;
}
.fw-300 {
  font-weight: 300 !important;
}
.fw-400 {
  font-weight: 400 !important;
}
.fw-500 {
  font-weight: 500 !important;
}
.fw-600 {
  font-weight: 600 !important;
}
.fw-700 {
  font-weight: 700 !important;
}
.fw-800 {
  font-weight: 800 !important;
}
.fw-900 {
  font-weight: 900 !important;
}
.fw-bold {
  font-weight: bold !important;
}

.hover:hover {
  opacity: 0.8;
}

.modal-backdrop {
  width: 100% !important;
  height: 100% !important;
}
.flex-text-column {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.text-up {
  text-transform: uppercase;
}

.top-0 {
  top: 0;
}

/* #region Width */
.w-5 {
  width: 5%;
}
.w-10 {
  width: 10%;
}
.w-15 {
  width: 15%;
}
.w-20 {
  width: 20%;
}
.w-25 {
  width: 25%;
}
.w-30 {
  width: 30%;
}
.w-35 {
  width: 35%;
}
.w-40 {
  width: 40%;
}
.w-45 {
  width: 45%;
}
.w-55 {
  width: 55%;
}
.w-60 {
  width: 60%;
}
.w-65 {
  width: 65%;
}
.w-70 {
  width: 70%;
}
.w-75 {
  width: 75%;
}
.w-80 {
  width: 80%;
}
.w-85 {
  width: 85%;
}
.w-90 {
  width: 90%;
}
.w-95 {
  width: 95%;
}

/* #endRegion */

.striped {
  background-color: #f3f6f8;
}
.MuiAppBar-root {
  z-index: 300 !important;
}
.MuiAppBar-colorPrimary {
  background-color: #fff !important;
}
.MuiPaper-elevation4 {
  box-shadow: none !important;
}
.css-1wa3eu0-placeholder {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
/* #region sidebar-left */
.makeStyles-root-10 {
  margin-left: 26px;
}
.makeStyles-formControl-11 {
  max-width: 100% !important;
  min-width: 100% !important;
  margin: 0 !important;
}
.MuiSelect-selectMenu {
  border: 1px solid #ced4da !important;
  min-height: 35px;
}
.MuiFormControl-root {
  max-width: 100% !important;
  width: 100%;
}
.MuiInput-underline:before {
  border-bottom: none !important;
}
.MuiChip-root {
  border-radius: 0 !important;
}
.MuiInput-root {
  width: 100%;
}
.css-1okebmr-indicatorSeparator {
  background-color: transparent !important;
}
.page-item.active .page-link {
  z-index: unset !important;
}
.top-nav {
  color: #ffffff;
  font-size: 14px;
  line-height: 22px;
  text-align: left;
}

.top-nav .nav-item .nav-link {
  /*color: #ffffff;*/
  /*opacity: 0.5;*/
  font-size: 14px;
  line-height: 22px;
  height: 50px;
  padding: 1em 0 1em 1em;
}
/*.top-nav .nav-item .nav-link:hover {*/
/*  opacity: 1;*/
/*  background-color: #002c5d;*/
/*}*/
/*.top-nav .nav-item.active .nav-link {*/
/*  opacity: 1;*/
/*  background-color: #002c5d;*/
/*}*/
.top-nav .nav-item .nav-link .nav-icon {
  width: 22px;
  height: 22px;
}
.top-nav .nav-item .nav-link .text {
  padding-left: 0.5em;
  padding-top: 1em;
}

.bottom-nav {
  /*color: #ffffff;*/
  font-size: 14px;
  line-height: 22px;
  text-align: left;
  position: absolute;
  bottom: 20px;
  width: 100%;
}

.bottom-nav .nav-item .nav-link {
  /*color: #ffffff;*/
  /*opacity: 0.5;*/
  font-size: 14px;
  line-height: 22px;
  height: 50px;
  padding: 1em;
}
.bottom-nav .nav-item .nav-link:hover {
  opacity: 1;
  background-color: #002c5d;
}
.bottom-nav .nav-item .nav-link:active {
  opacity: 1;
  background-color: #002c5d;
}
.bottom-nav .nav-item .nav-link .nav-icon {
  width: 22px;
  height: 22px;
}
/* #endRegion */

#termsAndConditions {
  color: rgb(0, 82, 204);
}
#termsAndConditions:hover {
  opacity: 0.7;
  text-decoration: underline;
}
